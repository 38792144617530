.noResultTableContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: var(--t-shirtSizes-s, 16px);

  h5 {
    font-size: 24px;
    font-style: normal;
    text-align: center;
    margin: 16px 0;
    line-height: 32px;
    font-weight: 600;
  }

  img {
    width: 240px;
    height: 240px;
  }
}

.height {
  height: calc(100vh - 198px);
}

.heightWithAlert {
  margin-top: -8px;
  height: calc(100vh - 198px - 98px)
}
