.formWrapper {
  padding-bottom: 40px;
  width: 500px;
}

.checkboxLabel {
    a {
      color: var(--colors-primary-400);
      text-decoration: underline;
      text-underline-position: under;
    }
  }

.organisationCardWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.boldText {
  font-weight: 500;
}

.checkboxLabel {
  a {
    color: var(--colors-goodstack);
    text-decoration: underline;
    text-underline-position: under;
  }
}

.marketingConsent {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}