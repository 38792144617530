.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-weight: 600;
  font-size: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 64px 24px;
  background-color: var(--colors-white);
  border-radius: 8px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.descriptionCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 16px;
  background-color: var(--colors-neutral-200);
  border-radius: 8px;

  .leftIcon {
    display: none;
    position: absolute;
    top: 120px;
    left: 100px;
    width: 100px;
    height: 100px;

    @media only screen and (min-width: 1280px) {
      display: block;
    }
  }

  .rightIcon {
    display: none;
    position: absolute;
    top: 0;
    right: 80px;
    width: 100px;
    height: 80px;

    @media only screen and (min-width: 1280px) {
      display: block;
    }
  }
}

.buttonWrapper {
  width: min-content;
  margin: 0 auto 32px auto;
}

.wavingHandBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background-color: var(--colors-neutral-200);
  border-radius: 100%;
}

.wavingHand {
  font-size: 40px;
  animation: wavingHand 1s ease-in-out infinite;
}

@keyframes wavingHand {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.upperCaseTitle {
  text-transform: uppercase;
}

.discountsTitle {
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  text-align: center;
}

.discountsDescription {
  max-width: 570px;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1280px) {
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@mixin cardWrapper {
  padding: 16px;
}

.cardWrapper {
  @include cardWrapper;
}

.cardWrapperInProgress {
  @include cardWrapper;

  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background-color: #8B4AFF29;

 > span {
   font-style: italic;
 }
}

.rejectedContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;

  .title {
    font-size: var(--sizes-5);
    font-weight: 600;
  }

  .button {
    width: fit-content;
  }

  @media only screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}

.rejectedDescription {
  display: flex;
  flex-direction: column;
  gap: 8px;

  > button {
    width: fit-content;
    margin-top: 16px;
  }
}

.rejectedImage {
  width: 216px;
  height: 160px;
}
