.cardContainer {
  border: 2px solid var(--colors-gray-70);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
}

@mixin buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.buttonContainerTopAlignment {
  @include buttonContainer;
  align-items: flex-start;
}

.buttonContainerBottomAlignment {
  @include buttonContainer;
  align-items: flex-end;
}

