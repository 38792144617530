.tableContainer {
  box-shadow: none;
  border-radius: 4px;
  padding: 12px 24px 0;
}

.tabs {
  display: flex;
  height: 64px;

  button {
    min-width: auto;
    padding: 0 0;
    margin: 6px 32px 6px 0;

    &:hover {
      color: var(--colors-neutral-400);
    }
  }

  &.MuiTabs-scroller {
    border: 1px solid;
  }
}

.tabs.MuiTabs-scroller {
  border: 1px solid;
}

.indicator {
  background-color: var(--colors-gray-80);
  margin-top: -1px;
  width: 100%;
  height: 1px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.table {
  border: none;

  thead tr th {
    padding: 12px 0;
  }

  td, th {
    border: none;
    padding: 12px 12px 12px 0;
    white-space: normal;
  }

  th:first-child, td:first-child {
    padding-left: 12px;
  }

  th:last-child, td:last-child {
    padding-right: 12px;
  }

  .footerCell {
    border-bottom: none;
    padding: 10px 0;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--colors-gray-100);
  margin-bottom: 16px;
  padding-left: 0;
  font-size: 20px;
  padding-right: 0px;
}

.headerCell {
  & > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }
}
