.container {
    background-color: var(--colors-primary-400);
    height: 48px;
    position: fixed; 
    width: 100vw; 
    z-index: 100;
    top: 0;
}

.content {
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    position: relative;
    padding: 0 16px;
}

.emoji {
    margin: 0 2px;
    font-size: 16px;
}

.boldText {
    font-weight: 500;
    margin-right: 4px;
}

.underline {
    text-decoration: underline;
}

.link {
    text-decoration: underline;
    margin-left: 4px;
}

.closeButton {
    position: absolute;
    right: 8px;
    top: 8px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}

.closeIcon {
    color: white;
    :hover {
        color: var(--colors-primary-200);
    }
}
