.badgeWaitingReview {
    margin-bottom: 16px;
}

.reviewText {
    margin-bottom: 16px;
    margin-top: 16px;
}

.formLink {
    color:  var(--colors-primary-400) !important;
    text-decoration: underline;
}

.bankDetailsBadge {
    span {
        white-space: normal;
        height: auto;
    }
}