.forgotPasswordText {
  text-align: left;
  margin-bottom: 48px;
  font-size: 12px;
  line-height: 24px;
  color: var(--colors-gray-600) !important;
}

.noAccountYet {
  padding-right: 8px;
  font-size: 14px;
  color: var(--colors-gray-600) !important;
}

.loginFormFooter {
  display: flex;
  padding-top: 48px;
}