$max-height: 370px;
$max-height-mobile: 290px;
$banner-bg: #faf1e2;
$linkedin-color: #3171AC;
$linkedin-hover-color: #004182;

@mixin promoTextMobileView {
    font-size: 18px;
    line-height: 24px;
}

@mixin bannerContainerMobileView {
    max-height: none;
    flex-direction: column-reverse;
}

@mixin bannerImageContainerMobileView {
    max-height: $max-height-mobile;
    width: 100%;
}

@mixin spotlightMobileView {
    display: none;
}

@mixin buttonsContainerMobileView {
    justify-content: space-between;
}

@mixin textContainerMobileView {
    padding: 24px 32px;
}

.bannerContainer {
    border-radius: 8px;
    width: 100%;
    max-height: $max-height;
    background-color: $banner-bg;
    overflow: hidden;
}

.textContainer {
    padding: 37px 48px;
}

.bannerLogs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.linkedInLogo {
    max-height: 28px;
}

.promoText {
    margin: 24px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: var(--colors-black);
}

.boldedPromoText {
    font-weight: 500;
}

.spotlight {
    border-radius: 4px;
    padding: 8px;
    background: var(--colors-black);
    color: var(--colors-white);
    font-weight: 700;
    font-size: 14px;
}

.bannerImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: $max-height;
    overflow: hidden;
}

.bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.buttonsContainer {
    display: flex;
    margin-top: 40px;
}

.getStarted {
    border-radius: 2px !important;
    min-width: 120px;
    height: 40px;
    padding: 0px 40px;
    background-color: $linkedin-color;
    color: var(--colors-white) !important;
    font-size: 16px;
    text-transform: none;
}

.getStarted:hover {
    background-color: $linkedin-hover-color;
}

.promoTextMobileView {
    @include promoTextMobileView;
}

.bannerContainerMobileView {
    @include bannerContainerMobileView;
}

.bannerImageContainerMobileView {
    @include bannerImageContainerMobileView;
    max-height: 200px;
}

.spotlightMobileView {
    @include spotlightMobileView;
}

.buttonsContainerMobileView {
    @include buttonsContainerMobileView;
}

.textContainerMobileView {
    @include textContainerMobileView;
}

@media (max-width: 1100px) {
    .promoText {
        @include promoTextMobileView;
    }
}
  
@media (max-width: 959px) {
    .bannerContainer {
      @include bannerContainerMobileView;
    }

    .bannerImageContainer {
        @include bannerImageContainerMobileView;
    }

    .spotlight { 
        @include spotlightMobileView;
    }

    .buttonsContainer {
        @include buttonsContainerMobileView;
    }

    .textContainerMobileView {
        @include textContainerMobileView;
    }
  }