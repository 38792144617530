.confirmationViewContainer {
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  a {
    color: var(--colors-gray-800);
    font-size: 1rem;
    text-decoration: underline;
    text-underline-position: under;
  }
}

.planeImage {
  width: 120px;
}

.helpCenterLink {
  color: var(--colors-primary-400) !important;
}