.container {
    display: flex;
    min-width: 100vw;
    background-color: white;
}

.titleAndBullets {
    display: flex;
    justify-content: center;
    flex: 1;
}

.leftScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }