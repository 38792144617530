.contentCtn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    width: 100%;
}

.textWrapper, .checkboxWrapper {
    margin-bottom: 16px;
}

.buttonCtn {
    margin-left: auto;
}