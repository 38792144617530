.fullScreen {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.loader,
.authLoader {
  padding: 40px;

  div {
    display: block;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.authLoader {
  display: inline-block;
}

.circularProgress {
  color: var(--colors-neutral-400);
}
