.bgImageWrapper {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  margin: 24px;
}

.mainGrid {
  max-width: 464px;
  margin: 0 auto;
  padding-top: 156px;
}

.bulletGrid {
  margin-bottom: 20px;
}

.titleGrid {
  margin-bottom: 24px;
  text-align: left;
}

.subTitleGrid {
  text-align: left;
  margin-bottom: 10px;
}

.listItem {
  align-items: center;
  padding: 0px;
  margin-top: 5px;
}

.listItemIcon {
  min-width: 30px;
}

.titleStyle {
  font-size: 24px;
  line-height: 32px;
}

.subTitleStyle, .bulletTextStyle span {
  font-size: 18px;
  letter-spacing: -0.18px;
  line-height: 24px; 
  color: var(--colors-gray-600);
}

