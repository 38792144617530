.editUserRoleModalFormFields {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
}

.boldText {
  font-weight: bold;
}

.errorAlert {
  margin: 0px 0px 20px 0px;
}