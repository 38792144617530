.logo {
  border-radius: 4px;
  margin-top: 5px;
}

.imageContainer {
  margin-bottom: 20px;
  padding: 0px;
}

.editDetailsBtnContainer {
  display: flex;
  justify-content: end;
}