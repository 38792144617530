
.userPageContainer {
  background-color: white;
  padding: 24px;
}

.userPageHeader {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
