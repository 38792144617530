.notificationBarClosed {
  top: 0;
}

.notificationBarOpened {
  div:first-child {
    top: 48px;
  }
}

.causesPortalLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  svg {
    width: 160px;
  }
}