.container {
  width: 100vw;
  height: 100vh;
  background-color: var(--colors-gray-80);
}

.close {
  position: absolute;
  top: 31px;
  left: 50px;
  display: flex;
  cursor: pointer;
  height: 12px !important;
  width: 12px;
  padding: 0;
  background: transparent;
  border: none;

  svg {
    height: 12px;
    width: 12px;
    path {
      fill: var(--colors-gray-500);
    }
  }
}