@mixin wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border: 2px solid var(--colors-gray-70);
}

.wrapper {
  @include wrapper;
}

.wrapperDisabled {
  @include wrapper;
  opacity: 0.5;
}

@mixin titleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  > span {
    font-weight: 500;
  }
}

.titleWrapper {
  @include titleWrapper;
}

.titleWrapperDisabled {
  @include titleWrapper;
  opacity: 0.5;
}

.description {
  margin: 0 28px;
}

.link {
  text-decoration: underline;
  text-decoration-color: var(--colors-primary-400);

  > span {
    color: var(--colors-primary-400);
  }
}
