#btnCloseDialog {
    cursor: pointer;
    top: 24px;
    right: 24px;
    color: transparent;
    border-radius: 5px;
    padding: 12px;
    border: none;
    position: absolute;
    line-height: 0;
}

.modalCtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.column {
    display: flex;
    width: 50%;
    height: 100%;
}

.rightColumn {
    align-items: center;
    justify-content: flex-end;
    background-image: url('apps/cause-dashboard/src/assets/images/canva-modal.webp');
    background-size: cover;
    background-position: center;
}

.leftColumn {
    background-color: var(--colors-white);
}

.leftCtn {
    display: flex;
    flex-direction: column;
    padding: 40px;
    position: relative;
    width: 100%;
    gap: 16px;
}

p {
    color: var(--colors-gray-600);
    line-height: 24px;
    font-size: 14px;
}

.title {
    color: var(--colors-black);
    font-weight: 600;
    line-height: 32px;
    font-size: 24px;
    padding: 0;
    margin: 0;
}

.box {
    border: 2px solid var(--colors-gray-80);
    border-radius: var(--radius-large, 8px);
    padding: 20px;
}

.subtitle {
    color: var(--colors-black);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
}

.paragraph1 {
    color: var(--colors-black);
    font-weight: 500;
    margin: 0;
}

.link {
    text-decoration: underline;
}
  
.modalFooter {
    position: absolute;
    bottom: 24px;
}