.dateRangePopper {
  background-color: var(--colors-white);
  padding: 0px 20px 20px;
  box-shadow: 0px 8px 32px #412276;
  border-radius: 4px;
}

.popperContainer {
  position: fixed;
  top: unset;
  left: unset;
}
