.mainCard {
  margin: 60px 0 40px 0;
  box-shadow: 0px 2px 16px rgba(33, 37, 53, 0.08) !important;
  border-radius: 8px;
  overflow: visible;
}

.headerTitle {
  display: block;
  padding: 0 24px;
}

.error {
  color: var(--colors-primary-400);
  font-size: 12px;
}