a {
  text-decoration: none;
}

.percentLogo {
  display: flex;
  justify-content: center;
}

.listItem {
  position: relative;
  margin: 0 !important;
  padding: 18px 8px 18px 28px;
}

.active {
  background-color: rgba($color: var(--colors-primary-400), $alpha: 0.04);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 2px;
    height: 36px;
    background-color: var(--colors-primary-400);
  }
}

.active,
.default,
.clickable {
  svg {
    width: 30px;
    height: 30px;
  }
}

.svgActive {
  path {
    fill: var(--colors-primary-400);
  }
}

.svgDefault {
  path {
    fill: var(--colors-gray-300);
  }
}

.svgActive {
  path {
    fill: var(--colors-primary-400);
  }
}

.svgClickable {
  path {
    fill: var(--colors-gray-700);
  }
}

.active,
.default,
.clickable {
  margin: 8px 0 8px 10px;
  top: 0px !important;

  div:first-child {
    min-width: 35px;
  }

  div:last-child {
    min-width: 35px;
  }

  span {
    font-size: 14px;
  }
}

.active {
  color: var(--colors-primary-400);
}

.default {
  color: var(--colors-gray-300) !important;
}

.clickable {
  color: var(--colors-gray-700) !important;
}
