.counter {
  font-size: 12px;
  line-height: 16px;
  color: var(--colors-black);
}

.textAreaDetail {
  margin-top: 8px !important;
  margin-bottom: 10px;
  align-items: center;
}
