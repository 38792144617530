$placeholder-color: var(--colors-gray-600);

.field {
  text-align: left;
  width: 100%;

  label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 14px;
    color: var(--colors-black);
    font-weight: 500;

    span {
      color: var(--colors-alert-400);
    }
  }

  input {
    outline: none;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--colors-black);
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: var(--colors-white);
    border: 1px solid var(--colors-gray-100);
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    padding: 12px 10px;

    &:valid {
      color: var(--colors-black);
    }
    &:invalid {
      color: var(--colors-primary-400);
    }
  }
}

.currency {
  display: inline-block;
  position: relative;
  input {
    padding-left: 10px;
  }
  &:after {
    position: absolute;
    content: attr(after-content);
    right: 20px;
    color: var(--colors-gray-600);
    top: 12px;
    font-weight: 500;
    font-size: 16px;
  }
}

.addOnDefault {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::placeholder {
  font-size: 14px;
  letter-spacing: 0.25px;
  color: $placeholder-color;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $placeholder-color;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholder-color;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $placeholder-color;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $placeholder-color;
}
