.label {
  margin-bottom: 5px;
  font-size: 12px;
}

.select {
  height: 48px;
  color: var(--colors-black);
}

.menuItem {
  color: var(--colors-black);
}

.labelContainer {
  color: var(--colors-black);
  width: 300px;
}
