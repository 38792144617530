.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  box-sizing: border-box;
}

.emailInputWrapper {
    padding-top: 24px;
}

.buttonWrapper {
    display: flex;
    width: 100%;
    padding-top: 24px;
}