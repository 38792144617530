.pageContainer {
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.heroContainer {
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

.hero {
  margin-top: 72px;
  margin-bottom: 72px;
  padding-top: 20px;
}
 
.heroLogos {
  position: absolute;
  top: 10%;
  right: 50px;
  min-width: 242px;
  min-height: 158px;
  width: clamp(242px, 37%, 484px);
  aspect-ratio:  701 / 473;
  z-index: 1;
}

.heroTitle {
  font-size: 44px;
  font-weight: 500;
  font-family: 'PPRightGroteskMedium', sans-serif;
  line-height: 46px;
  max-width: 60%;
  margin-bottom: 16px;
  z-index: 2;
}

.heroDescription {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  max-width: 585px;
}

.heroAnnotation {
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'PPRightGroteskMedium', sans-serif;
}

@media (max-width: 768px) {
  .heroLogos {
    display: none;
  }

  .heroTitle {
    max-width: 700px;
  }
}

.searchAndFooterContainer {
  background-color: white;
  -webkit-box-shadow: inset 0px 100px 0px 0px #f8f8f8;
  -moz-box-shadow: inset 0px 100px 0px 0px #f8f8f8;
  box-shadow: inset 0px 100px 0px 0px #F8F6F2;
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
}