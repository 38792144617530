@keyframes heartBeat {
  0% {
    transform: scale(1) translateY(0px);
  }
  50% {
    transform: scale(1.5) translateY(-3px);
  }
  100% {
    transform: scale(1) translateY(0px);
  }
}

.heartInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 40%;
}

.heartBeat {
  animation: heartBeat 0.5s ease;
  animation-fill-mode: forwards;
}

.heartWrapper {
  position: relative;
}

.bookCallCard {
  background: white;
  border-radius: 8px;
  margin-bottom: 24px;
}

.contentContainer {
  display: flex;
  padding: 16px 18px;
  gap: 16px;
  align-items: center;
}

.buttonInnerContainer {
  display: flex;
  justify-content: center;
}

.textSubHeader {
  font-size: 14px;
  color: var(--colors-gray-600);
}
.rightContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-left: auto;
}
