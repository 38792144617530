.container {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px;
  background-color: var(--colors-white);
  border-radius: 4px;
  max-width: 800px;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridItem {
  margin-bottom: 12px;
}

.heading {
  font-size: 1.25rem;
  line-height: 24px;
}

.description {
  line-height: 24px;
  font-weight: 400;
  color: var(--colors-gray-600);
}
