.requestToJoinContainer {
    display: flex;
    flex-direction: column;
    width: 224px;
    justify-content: start;
}

.requestToJoinTextWrapper {
    padding-bottom: 6px;
}

.organisationName {
    font-weight: 600;
}