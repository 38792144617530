.productCategoriesWrapper {
  margin-top: var(--sizes-8);
  display: flex;
  flex-direction: column;
  gap: var(--sizes-8);
}

.productOffersWrapper {
  border-radius: var(--border-radii-large);
  background-color: var(--colors-white);
  padding-bottom: var(--sizes-8);
  box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.02);
}

.productOffersPageGrid {
  padding: 0px 24px;
}

.sectionHeader {
  padding: 24px 24px 12px;
  background-color: var(--colors-white);
  border-radius: var(--border-radii-large);
}

.sectionTitle {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.sectionDescription {
  padding-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colors-gray-600)
}
