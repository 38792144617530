.highlighter {
  border-radius: 8px;
  font-size: 14px;
}

.wrapper {
  position: relative;
}

.copy {
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    > svg {
      > path {
        fill: var(--colors-gray-70) !important;
      }
    }
  }
}
