.address, .charityNumber, .status {
  color: var(--colors-gray-700);
}

.address {
  margin-top: 10px;
}

.charityNumber {
  margin-top: 30px;
  color: var(--colors-gray-700);
}

.status {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: var(--colors-gray-700) !important;
  p {
    margin-right: 5px;
    color: var(--colors-gray-700) !important;
  }
}
