.subtitle {
  padding-top: 10px;
  max-width: 560px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}
