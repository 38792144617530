.authBackgroundImage {
  display: none;
}

@media only screen and (min-width: 769px) {
  .authBackgroundImage {
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
  }
}
