.container {
    display: flex;
    padding: 24px;
    width: 100%;
    justify-content: space-between;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
}

.websiteCtn {
    display: flex;
}

.websiteWrapper {
    border-bottom: 1px solid #8a4aff;
    margin-top: 8px;
}

.image {
    height: 64px;
    width: 64px;
    border-radius: 4px;
}