.passwordResetText {
  color: var(--colors-gray-700);
  line-height: 24px;
  text-align: left;
  margin-bottom: 48px;
}

.passwordText {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}

.footerText {
  padding-right: 8px;
  font-size: 14px;
  color: var(--colors-gray-600) !important;
}

.passwordFormFooter {
  display: flex;
  padding-top: 48px;
}