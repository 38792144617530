.listItem {
  border-top: 1px solid var(--colors-gray-80);

  &:last-of-type {
    border-bottom: 1px solid var(--colors-gray-80);
  }
}

.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.separator {
  border-right: 1px solid var(--colors-gray-100);
  height: 32px;
  display: inline-block;
  margin: 0 12px;
}

.titleAndCTAs {
  width: 100%;
  display: flex;
  margin: 24px 0;
  align-items: center;

  h6 {
    white-space:nowrap;
    font-weight: 600;
    line-height: 24px;
  }
}

.actionWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.alertWrapper {
  margin-top: 12px;
}

.clearText {
  padding: 4px 8px;
  margin-left: 8px;
  cursor: pointer;
  color: var(--colors-gray-900)
}