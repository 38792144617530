.documentsStatusContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--colors-white);
  padding: 48px;
  max-width: 640px;
  width: 100%;
  margin: auto;
  border-radius: 16px;
  gap: 24px;
  border: 4px solid #efede9;

  h5 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #404858;
  }

  a {
    display: block;
    color: #404858;
    text-decoration: underline;
  }
}

.iconContainer {
  display: flex;
}

.supportLink {
  display: inline;
  color: #8b4aff;
}

.googleSetupContainer {
  width: 100%;
  border: 1px solid #eceff3;
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  margin-top: 16px;

  h6 {
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
    font-weight: 400;
    color: #404858;
    width: 95%;
  }
}
