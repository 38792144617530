.field {
  width: 100%;
  text-align: left;
  color: var(--colors-neutral-400);

  label {
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: var(--colors-black);
  }

  div {
    margin-top: 0px;
    &::before,
    &::after {
      border-bottom: none;
    }

    &:focus {
      outline: none;
    }
  }

  input {
    outline: none;
    display: block;
    width: 100%;
    height: 48px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--colors-black);
    background-color: var(--colors-white);
    background-clip: padding-box;
    border: 1px solid var(--colors-gray-200);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:disabled {
      background: var(--colors-gray-70);
      border: 1px solid var(--colors-gray-200);
      box-sizing: border-box;
      border-radius: 4px;
      color: var(--colors-gray-500);
    }
  }
}

.fieldInput {
  border: 1px solid var(--colors-primary-400) !important;
}

input::-webkit-input-placeholder {
  color: var(--colors-gray-700);
  font-size: 12px;
}

input::-moz-placeholder {
  color: var(--colors-gray-700);
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.addonLabelClassname {
  float: right;
  text-decoration: underline;
  color: var(--colors-gray-700);
  font-size: 12px;
  text-underline-position: under;
}
