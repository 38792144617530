.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  width: 100%;;

}

.backButton {
  background-color: transparent;
  font-size: 12px;
}

.resendButtonGrid {
  margin-top: 30px;
}


.signedUp {
  margin-top: 20px;

  a {
    text-decoration: underline;
    text-underline-position: under;
    color: var(--colors-gray-600);
  }
}


.helpCenterLink {
  color: var(--colors-primary-400) !important;
  text-decoration: underline;
  text-underline-position: under;
}