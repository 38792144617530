.subtext {
  font-size: 12px;
  font-weight: normal;
  color: var(--colors-gray-900);
  padding: 0;
  margin: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.optionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    margin-left: 10px;
    color: var(--colors-gray-700);
  }
}
