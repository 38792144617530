.container {
  z-index: 100;
  left: 224px;
  width: calc(100vw - 224px);
  position: fixed;
}

.breadcrumbs {
  ol {
    flex-wrap: nowrap;
    margin-left: 40px;
  }
}

.breadcrumb {
  color: var(--colors-black);
  white-space: nowrap;
}

.breadcrumbActive {
  color: var(--colors-black);
  white-space: nowrap;
}

.userDetail {
  display: block;
  justify-content: center;
  width: auto;

  h6:first-child {
    font-weight: 600;
    color: var(--colors-black);
  }
}

.nameText {
  color: var(--colors-neutral-400);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  white-space: nowrap;
}

.roleText {
  color: var(--colors-gray-500);
}

.userProfileMenu {
  a {
    color: var(--colors-neutral-400);
    &:active {
      color: var(--colors-neutral-400);
    }
  }

  div:nth-last-child(2) {
    box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16) !important;
  }
}

.userProfileMenuItem {
  width: 224px !important;
  svg {
    margin-right: 12px;
  }
}

.dropdown {
  transform: rotate(180deg);
}

.menuItem {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  border-left: 1px solid var(--colors-gray-100);

  img {
    border-radius: 4px;
    margin-left: 15px;
  }
}

.link--disabled {
  pointer-events: none;
}

.verifiedNotification, .unverifiedNotification, .rejectedBankDetails {
  width: 100%;
  height: 70px;
}

.notificationBarClosed {
  margin-top: 0px;
}

