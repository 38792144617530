.wrapper {
  padding: 0 32px;
}

.tasksWrapper {
  > div {
    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:not(:first-child):not(:last-child) {
      border-top: none;
    }

    &:last-child {
      border-top: none;
      border-radius: 0 0 8px 8px;
    }
  }
}

.completeProfileTitle {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.progressBarWrapper {
  margin-bottom: 24px;
}
