.causesPortalLogo {
  margin: 44px 0px 44px 80px;
  align-self: flex-start; 
}

.causesPortalLogoCentralCard {
  position: absolute;
  top: 44px;
  left: 80px;
}
