.titleCardContainer {
  padding: 24px 24px 32px;
  background-color: var(--colors-white);
  border-radius: 4px;
}

.title {
  margin-bottom: 16px;
  display: inline-block;
  color: var(--colors-black);
}

.description {
  color: var(--colors-gray-900);
  margin-bottom: 24px;
}
