.logInOutButtonDiv {
  margin-left: auto !important;
}

.appBar {
  box-shadow: none !important;
  background-color: var(--color-gray-100) !important;
  display: flex;
  align-items: center;
  height: 96px !important;
  color: var(--color-black);
}

.navLink {
  background: none;
  border: none;
}

.navLink:first-child{
  margin-right: 32px !important;
}
.navLink:last-child {
  margin-left: 32px;
}
.navLink:hover {
  text-decoration: underline;
}

.navDivider {
  border-left: 1px solid;
  border-color: #E1E2E3;
}
