.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;

  h3 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #494550;
  }

  a {
    color: #8b4aff !important;
    text-decoration: underline;
  }
}

.resultBadgeWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}
