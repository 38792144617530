.innerRedCircle {
  height: 72px;
  width: 72px;
  position: absolute;
}

.cross {
  height: 22px;
  width: 26px;
  position: absolute;
}
.outerRedCircle {
  height: 80px;
  width: 80px;
  position: absolute;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}
