@mixin imageUploaderTypography {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.changeImageButton {
  color: var(--colors-primary);
  background-color: var(--colors-gray-70);
  padding: 12px 24px;
  margin-bottom: 8px;
  margin-right: 10px;
  border: none;
  min-width: 139px;
  display: inline-block;
  text-align: center;
  transition: background-color 0.3s ease-out;
  transition: all 0.3s;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
