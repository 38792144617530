.submitFileButton {
    margin-top: 24px;
    display: flex;
    justify-content: end;
}

.placeholderText {
    margin-bottom: 4px;
}

.label, .labelDisabled {
    display: flex;
    border: 1px solid var(--colors-gray-80) !important;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    justify-content: space-between;
    margin-top: 5px;

    p {
        margin-bottom: 4;
        color: var(--colors-black);
    }
    button {
        height: 32px !important;
        color:  var(--colors-primary-600) !important;
        padding: 0 24px;
        &:focus-visible {
            outline: 1px var(--colors-gray-70) ;
        }
    }
}

.helperText {
    margin-top: 8px;
}

.labelDisabled {
    background-color: var(--colors-gray-80);
}

.fieldError {
    color: var(--colors-primary-400);
    display: block;
}

.textWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 28px;
    color: var(--colors-gray-800);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 5.33px 3.33px;
    background-color: var(--colors-alert-100);
    border-radius: 4px;
  
    svg {
      path {
        fill: var(--colors-alert-400);
      }
    }

    a {
        color: var(--colors-gray-800);
        text-decoration: underline;
        font-weight: 700;
    }
}

.uploadDocument {
    margin-top: 24px;
}

.donationText {
    margin-top: 16px;
}

.bankDetailsFormHeader {
    color: var(--colors-neutral-500) !important;
}
