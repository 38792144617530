.popupContentContainer {
    width: 400px;
    height: auto;
    position: absolute;
    right: 24px;
    bottom: 24px;
    outline: none;
}

.closeButton {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    background-color: rgba(255,255,255, 0.7);
    border-radius: 4px;
}

@media (max-width: 959px) {
    .popupContentContainer {
        position: fixed;
        right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%);
    }
}