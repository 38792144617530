.inviteUserModalFormFields {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
}

.helpLink {
  text-decoration: underline;
  text-underline-position: under
}
