@mixin baseText {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--colors-gray-900);
}

.claimsText {
  margin: 20px 0;
}

.dialogDescription {
  @include baseText;
}

.dialogBody {
  margin: 16px 24px;

  span {
    font-weight: bold;
    text-decoration: none;
  }
}

.subtitleContainer {
  padding: 16px;
  margin-top: 16px;
  border-radius: 4px;
  background-color: var(--colors-gray-70);

  h6 {
    @include baseText;
  }
}

.subtitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--colors-black);
  margin-bottom: 16px;
}

.buttonContainer {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;

  button:nth-child(2) {
    margin-left: 16px;
  }
}

.footerCell {
  border-bottom: none;
}

.claimButtonContainer {
  width: 224px;
  min-width: 200px;
}