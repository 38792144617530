.errorViewContainer {
  display: flex;
  min-height: 100vh;
}

.errorContainer {
  width: 100%;
  background-color: var(--colors-white);
  margin-top: 10%;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error {
  padding: 0 15px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    color: var(--colors-gray-600);
    text-decoration: underline;
  }
}

.errorIconContainer {
  margin-bottom: 16px;
}

@media only screen and (min-width: 769px) {
  .errorContainer {
    width: 464px;
    height: 592px;
  }

  .error {
    padding: 0 52px;
  }
}
