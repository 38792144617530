.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paginationButtonDisabled {
  color: var(--colors-gray-500) !important;
}

.paginationButton {
  color: var(--colors-neutral-400);
}
