
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.subtext {
  font-size: 12px;
  color: var(--colors-gray-700) !important;
  margin-bottom: 18px;
}

.copyWrapper {
  display: flex;
  margin-bottom: 40px;
}

.cancelButtonWrapper {
  display: flex;
  justify-content: center;
}