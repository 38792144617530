.agentVerificationText {
  color: var(--colors-gray-800);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: -11px;
}

.footer {
  margin-top: auto;
  margin-bottom: 20px;
  padding: 0 10px;
  color: var(--colors-gray-600);
  text-align: center;
  min-width: 536px;
  h6 {
    margin: 30px 0;
  }

  .termsOfUse {
    text-decoration: underline;
    text-underline-position: under;
  }
}

.typography {
  margin-top: 20px;
}

.poweredByPercent {
  text-decoration: none;
}
