.formWrapper {
  padding-bottom: 40px;
  width: 480px;
}

.signedUp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
}

.checkboxLabel {
  a {
    color: var(--colors-goodstack);
    text-decoration: underline;
    text-underline-position: under;
  }
}

.marketingConsent {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.alertBody {
  margin: 24px 8px 0 8px;
}
