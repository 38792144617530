.listItem {
  border-top: 1px solid var(--colors-gray-80);

  &:last-of-type {
    border-bottom: 1px solid var(--colors-gray-80);
  }

}

.seeDetails {
  width: 74px !important; 
  padding-right: 1rem !important; 
  cursor: pointer;
}

.tableHeader {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--colors-gray-80);
  padding: 8px 0 20px;
  & > h6 {
    margin-right: 16px;
  }
}

.detailsWrapper {
  padding: 16px 24px 36px;
  background-color: var(--colors-white);
  border-radius: 4px;
}

.detailsHeadersWrapper,
.detailsInfoWrapper {
  box-sizing: border-box;
  padding: 0!important;
  font-size: 14px;
  line-height: 1.5rem;

  & > div {
    width: 13%;
    min-width: 120px;
    padding: 16px 10px 16px 0 !important;
  }
}

.detailsHeadersWrapper {
  font-weight: 500;
}

.detailsInfoWrapper {
  border-bottom: 1px solid var(--colors-gray-80);
  border-top: 1px solid var(--colors-gray-80);
}

.title {
  width: 100%;
  display: flex;
  margin: 12px;
  align-items: center;

  h6 {
    white-space:nowrap;
    font-weight: 600;
    line-height: 24px;
  }
}
