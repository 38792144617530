.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: var(--colors-gray-70);
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 224px;
  align-items: center;
}

.pageContent {
  padding: 25px;
  width: 100%;
  max-width: 1370px;
  box-sizing: border-box;
  margin-top: 70px;
}

.sideMenuWrapper {
  position: fixed;
  padding-top: 19px;
  width: 224px;
  height: 100%;
  background-color: white;
  z-index: 1000;
  box-shadow:  0px 3px 34px -17px rgba(0,0,0,0.3);
}