.agentVerificationText {
    color: var(--colors-gray-800);
  }

.ctaContainer {
    text-align: left;
    margin-top: 3rem;
    border: 2px solid var(--colors-gray-80);
    padding: 24px;
    border-radius: var(--sizes-2);
    transition: 0.4s all;
  }
  
.ctaContainer:hover {
    border: 2px solid var(--colors-gray-100);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
}

.ctaFlex {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.ctaText {
    text-align: left;
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: var(--colors-gray-800);
}

.ctaTitle {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 0.5rem;
    color: var(--colors-black);
}

  