@mixin bannerCommon {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  height: 48px;
  line-height: 45px;
  width: 100%;
  text-align: center;
  margin: 0px;
  max-width: 100%;

  p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    
    a {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
}

.verificationNotification {
  @include bannerCommon;
  background: var(--colors-black);
  p {
    color: var(--colors-white);
    a {
      color: var(--colors-primary-400);
    }
    .openModal {
      background: var(--colors-black);
      color: var(--colors-white)
    }
  }
}

.generalNotification {
  @include bannerCommon;
  background: var(--colors-primary-400);
  p {
    color: var(--colors-white);
    a, .openModal {
      color: var(--colors-white);
    }
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 20px;
}

.closeNotificationButton {
  position: absolute;
  right: 0;
  top: 8px;
}

.emptyBanner {
  height: 0;
}

.openModal {
  text-decoration: underline;
  text-underline-offset: 3px;
  background: none; 
  border: none; 
  cursor: pointer; 
  padding: 0; 
  font: inherit; 
}

.backgroundBlur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  z-index: 1;
}

@media (max-width: 1080px) {
  .verificationNotification,
  .generalNotification {
    p {
      font-size: 12px;
    }
  }

  .verificationNotification {
    p {
      max-width: 540px;
    }
  }
}