.agentVerificationDocumentsContainerDefault {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 24px;
  min-height: 100vh;
}

.agentVerificationDocumentsContainerWithBanner {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 24px;
  margin-top: 48px;
  min-height: calc(100vh - 48px);
}

.documentsContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: var(--colors-white);
  padding: 48px;
  max-width: 640px;
  width: 100%;
  margin: auto;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #404858;
  gap: 24px;
  border: 4px solid #efede9;

  h5 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  ul {
    margin: 0;
    padding-left: 16px;
  }

  p {
    margin: 0;
    color: #404858;
  }
}

.fileInputWrapper {
  button {
    width: 100%;
    max-width: 160px;
    align-self: flex-start;
  }
}

.termsAndConditionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;
  color: #0d0d0d;

  a {
    text-decoration: underline;
    color: #0d0d0d;
  }
}

.footerWrapper {
  display: flex;
  justify-content: center;
}
